.alert-curtain {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 4;
}
.alert-prompt {
  position: fixed;
  width: calc(100% - 6rem);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(5px);
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  left: 0;
  right: 0;
  max-width: 18rem;
  transform: translateY(-100vh);
  border-top: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.alert-prompt--active {
  transform: translateY(0);
}
.alert-prompt__title {
  color: var(--text-color);
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
.alert-prompt__info-content {
  color: var(--text-color);
  font-weight: 500;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 1.5rem;
  flex: 1;
  font-size: 0.875rem;
  text-align: center;
}
.alert-prompt__controls {
  display: flex;
  justify-content: flex-end;
}

.alert-prompt__controls button {
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  /* background-color: red; */
  padding: 0.75rem;
  font-size: 1rem;
  border-top: 1px solid rgb(219, 218, 218);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-weight: 600;
  transition: all 150ms ease-in-out;
}
.alert-prompt__controls button:hover {
  background-color: #f6f4fe;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
