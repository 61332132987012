.member-card {
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
  box-shadow: 0px 3px 0px rgb(227 227 227), 0 6px 6px rgba(0, 0, 0, 0),
    0 6px 6px rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
}
.member-card:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0px 3px 0px rgb(227 227 227), 0 6px 6px rgba(0, 0, 0, 0.025),
    0 6px 6px rgba(0, 0, 0, 0.12);
}
.member-card__main-infos {
  background-color: var(--bg-color);
  border-radius: 0.25rem;
  padding: 0.5rem;
  display: flex;
  margin-bottom: 0.5rem;
  background-color: #eff1f2;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23dbdae0' fill-opacity='0.35' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 3rem;
  background-position: center;
}
.member-card__main-infos > img {
  height: 104px;
  width: 104px;
  border: 0.25rem white solid;
  border-radius: 0.25rem;
  margin-right: 1rem;
  object-fit: cover;
}
.member-card__main-infos__identity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 104px - 2rem);
}
.member-card__main-infos__identity span:first-of-type {
  color: var(--text-color);
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 0.825rem;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.member-card__main-infos__identity span:nth-of-type(2) {
  color: var(--text-color);
  opacity: 0.7;
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.member-card__main-infos__identity span:last-of-type {
  color: var(--text-color);
  opacity: 0.7;
  font-weight: 700;
  font-size: 0.825rem;
  line-height: 2rem;
}
.member-card__sub-infos {
  padding: 0.25rem;
  background-color: var(--bg-color);
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23dbdae0' fill-opacity='0.35' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 3rem;
  background-position: center;
}
.member-card__sub-infos span {
  flex: 1;
  text-align: center;
  color: var(--text-color);
  opacity: 0.7;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.member-card__sub-infos span svg {
  margin-right: 0.5rem;
}
.member-card__sub-infos span:nth-of-type(2) {
  border: 2px solid white;
  border-top: none;
  border-bottom: none;
}
.member-card__more {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.member-card__more__skills {
  display: flex;
  justify-content: space-between;
}
.member-card__more__skills .skill-icon {
  margin-right: 0.25rem;
}
.member-card__more__skills .skill-icon:last-of-type {
  margin: 0;
}
.member-card__more__divider {
  height: 2rem;
  width: 2px;
  background-color: var(--bg-color);
  margin: 0 0.5rem;
  opacity: 0;
}
.member-card__more__socials {
  flex: 1;
  max-width: 7.25rem;
  display: flex;
  justify-content: center;
}
.member-card__more__socials__display {
  padding-left: 1.5rem;
  padding-top: 2px;
  width: 100%;
  height: 2rem;
  min-height: 2rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: var(--grey-color);
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 200ms ease;
  position: relative;
  cursor: pointer;
}
.member-card__more__socials__display span {
  display: flex;
  align-items: center;
}

.member-card__more__skill-placeholder {
  height: 2rem;
  width: 2rem;
  border: 1px solid var(--bg-color);
  border-radius: 0.25rem;
  background-color: var(--bg-color);
  margin-right: 0.25rem;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23dbdae0' fill-opacity='0.35' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 3rem;
  background-position: center;
}
.dc-logo-scoring {
  height: 0.875rem;
  width: 0.875rem;
  margin-right: 0.5rem;
}
.member-card__more__skills__more {
  min-height: 2rem;
  min-width: 2rem;
  border: 1px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--grey-color);
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 200ms ease;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 2px 0px var(--bg-color);
  border-radius: 0.15rem;
  font-size: 0.75rem;
}
