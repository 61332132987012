.base-button--mobile-condensed {
  padding: 8px 16px;
}
.base-button--mobile-condensed .base-button__content__text {
  display: none;
}
.base-button--mobile-condensed .base-button__content__icon {
  margin: 0;
}

.base-button {
  cursor: pointer;
  border-radius: 8px;
  /* padding: 0.5rem 0.825rem; */
  padding: 0.5rem 1rem;
  font-weight: 600;
  border: none;
  font-size: 1rem;
  line-height: 1rem;
  transition: all 200ms ease;
  border: 2px solid;
  white-space: nowrap;
}

.base-button:hover {
  filter: brightness(1.1);
}
.base-button:active {
  filter: brightness(0.9);
}
.base-button--disabled {
  opacity: 0.3;
  /* background-color: rgb(199, 199, 199) !important;
  border-color: rgb(199, 199, 199) !important; */
}
.base-button--disabled:hover,
.base-button--disabled:active {
  filter: brightness(1);
}

.base-button--is-loading .base-button__content .base-button__content__text,
.base-button--is-loading .base-button__content .base-button__content__icon {
  opacity: 0.2;
}
.base-button__content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-button__content__text {
  white-space: nowrap;
  overflow: hidden;
}
.base-button__content__icon {
  margin-right: 0.5rem;
}
.base-button__content__loading {
  position: absolute;
}
@media screen and (min-width: 768px) {
  .base-button--mobile-condensed {
    padding: 8px 16px;
  }
  .base-button--mobile-condensed .base-button__content__text {
    display: block;
  }
  .base-button--mobile-condensed .base-button__content__icon {
    margin-right: 8px;
  }
}
