/* MAIN */
.mentorat {
  background-color: var(--bg-color-dark);
  --blue-text-color: #94acc6;
  overflow-x: hidden;
  position: relative;
  overflow-y: hidden;
}
.mentorat_wide-bg {
  display: none;
}
/* HERO SECTION */
.mentorat_hero {
  background: linear-gradient(-120deg, #27b2ed50, #7a80ba12);
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;
  transition: all 0ms ease-in-out;
  position: relative;
  z-index: 0;
  overflow: visible;
  padding-bottom: 16px;
}
.mentorat_hero_pic {
  background: url("./../assets/mentorat/hugo-1.png") no-repeat
    calc(100vw - 430px) bottom;
  background-size: 625px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.mentorat_hero_pic--code {
  transition: opacity 0.5s ease-in-out;
  opacity: 0 !important;
  background: url("./../assets/mentorat/hugo-1-code.png") no-repeat
    calc(100vw - 430px) bottom;
  background-size: 625px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: picCodeAnimation 2s ease-in-out infinite alternate;
}
.mentorat_hero_intro {
  color: var(--blue-text-color);
  font-weight: 600;
  line-height: 28px;
  font-size: 14px;
  margin-bottom: 8px;
  text-align: center;
  position: relative;
}
.mentorat_hero_title {
  font-size: 32px;
  font-weight: 800;
  line-height: 35px;
  background: linear-gradient(45deg, #8b79b9, #799ab9);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 32px;
  position: relative;
  max-width: 400px;
}
.mentorat_hero_subtitle {
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(45deg, #8b79b9, #799ab9);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 24px;
  padding: 0 16px;
  position: relative;
  max-width: 400px;
}
.mentorat_hero_cta-section {
  width: 100%;
}
.mentorat_hero_description-section {
  width: 100%;
  position: relative;
  max-width: 740px;
}
.mentorat_hero_description-container {
  width: 100%;
  padding: 16px;
  position: relative;
}
.mentorat_hero_description {
  font-size: 14px;
  font-weight: 500;
  color: var(--blue-text-color);
  max-width: 60%;
  margin: 0;
  margin-bottom: 24px;
}
.mentorat_hero_cta-container {
  height: 220px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.mentorat_hero_cta_label {
  color: #6caae0;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 12px;
  display: block;
}
.mentorat_hero_cta_label--secondary {
  color: #a691e8;
}
.mentorat_hero_cta_next-session {
  height: 51px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #081826;
  color: #6caae0;
  font-weight: 600;
  border: 1px solid #53799d;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: none;
}
.mentorat_hero_cta-container button {
  width: 100%;
  height: 51px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #53799d;
  background-color: #53799d;
  font-size: 16px;
  font-weight: 600;
  color: white;
}
.mentorat_hero_cta-container button:first-of-type {
  margin-bottom: 12px;
}

.mentorat_hero_cta_button--secondary {
  background-color: #0f0826 !important;
  color: #a691e8 !important;
  border: 1px solid #a691e8 !important;
  border-radius: 8px;
}
/* EXPERT SECTION */
.mentorat_expert {
  width: 100%;
  padding: 40px 0 24px 0;
  position: relative;
  overflow: hidden;
}
.mentorat_expert_title {
  display: block;
  font-size: 32px;
  font-weight: 800;
  line-height: 35px;
  background: linear-gradient(45deg, #8b79b9, #799ab9);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 48px;
  position: relative;
  padding: 0 16px;
}
.mentorat_expert_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  text-align: center;
  margin-bottom: 32px;
}
.mentorat_expert_item img {
  width: 150px;
  margin-bottom: 24px;
}
.mentorat_expert_item span {
  font-size: 24px;
  font-weight: 700;
}
.mentorat_expert_item--0 span {
  color: #94c69c;
}
.mentorat_expert_item--1 span {
  color: #c094c6;
}
.mentorat_expert_item--2 span {
  color: #94c6c2;
}
.mentorat_expert_item--3 span {
  color: #949ec6;
}
.mentorat_expert_item p {
  font-size: 14px;
  font-weight: 500;
}
.mentorat_expert_item--0 p {
  color: #7dba83;
}
.mentorat_expert_item--1 p {
  color: #af7dba;
}
.mentorat_expert_item--2 p {
  color: #7dbab2;
}
.mentorat_expert_item--3 p {
  color: #7d8cba;
}
.mentorat_expert_color-bubble {
  position: absolute;
  width: 1000px;
  height: 1000px;
  z-index: -1;
  opacity: 0.12;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #94c69c 0deg,
    #94c6c2 76.19deg,
    #c094c6 222.44deg,
    #94aac6 360deg
  );
  border-radius: 100%;
  filter: blur(300px);
  transform: rotate(-180deg);
}
/* PROBE SECTION */
.mentorat_probe {
  width: 100%;
  padding-top: 40px;
  position: relative;
  background: linear-gradient(180deg, #2c2343 0%, #354e64 100%);
  z-index: -2;
}
.mentorat_probe_title {
  display: block;
  font-size: 32px;
  font-weight: 800;
  line-height: 38px;
  color: #c6d4e1;
  text-align: center;
  margin-bottom: 24px;
  position: relative;
  max-width: 400px;
  padding: 0 16px;
  margin-left: auto;
  margin-right: auto;
}
.mentorat_probe_illustrations {
  position: relative;
  max-width: 100%;
}
.mentorat_probe_illustrations img {
  width: 100%;
}
.mentorat_probe_illustrations--fb {
  position: absolute;
  z-index: -1;
  max-height: 170px;
  max-width: 170px;
  top: -50px;
  left: -25px;
  animation: logosAnimation 2.2s 3120ms ease-in-out infinite alternate;
}
.mentorat_probe_illustrations--probe {
  padding: 16px;
}
.mentorat_probe_illustrations--html {
  position: absolute;
  max-height: 181px;
  max-width: 181px;
  top: 170px;
  right: -35px;
  animation: logosAnimation 2s ease-in-out infinite alternate;
}
.mentorat_probe_illustrations--yt {
  position: absolute;
  max-height: 90px;
  max-width: 90px;
  bottom: -70px;
  left: 0px;
  animation: logosAnimation 1.75s 1220ms ease-in-out infinite alternate;
}
.mentorat_probe_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 16px;
  text-align: right;
  padding-top: 24px;
}
.mentorat_probe_content span {
  color: #c6d4e1;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.mentorat_probe_content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #ffffff;
  max-width: 250px;
}
.mentorat_probe_illustrations-bottom {
  position: relative;
  max-width: 100%;
  height: 230px;
}
.mentorat_probe_illustrations-bottom--hugo {
  position: absolute;
  max-width: 130%;
  bottom: 0;
  left: -50%;
}
.mentorat_probe_illustrations-bottom--tw {
  position: absolute;
  max-width: 250px;
  max-height: 250px;
  bottom: 45px;
  right: -15px;
  animation: logosAnimation 1.6s 320ms ease-in-out infinite alternate;
}
.mentorat_probe_illustrations-bottom--js {
  position: absolute;
  bottom: 0;
  max-width: 110px;
  max-height: 110px;
  bottom: 0px;
  right: 80px;
  animation: logosAnimation 1.8s 2320ms ease-in-out infinite alternate;
}
/* ADVANTAGES SECTION  */
.mentorat_advantages {
  width: 100%;
  padding: 40px 0 24px 0;
  position: relative;
}
.mentorat_advantages_title {
  display: block;
  font-size: 32px;
  font-weight: 800;
  /* line-height: 35px; */
  background: linear-gradient(45deg, #8b79b9, #799ab9);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  /* max-width: 400px; */
  padding: 0 16px;
}
.mentorat_advantages_content {
  padding: 0 24px;
}
.mentorat_advantages_item {
  position: relative;
  margin-bottom: 56px;
}
.mentorat_advantages_item:last-of-type {
  margin-bottom: 32px;
}
.mentorat_advantages_item img {
  position: absolute;
  width: 128px;
  height: 128px;
  left: 0;
  top: -25px;
}
.mentorat_advantages_item span {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  background: linear-gradient(100.55deg, #8b79b9 8.75%, #799ab9 57.85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.mentorat_advantages_item p {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #aec0d7;
}
/* TESTIMONIALS SECTION  */
.mentorat_testimonials {
  width: 100%;
  padding-top: 40px;
  position: relative;
  background: linear-gradient(
    237.99deg,
    rgba(39, 178, 237, 0.28125) 22.67%,
    rgba(122, 128, 186, 0.07) 73.46%
  );
  /* z-index: -2; */
}
.mentorat_testimonials_title {
  display: block;
  font-size: 32px;
  font-weight: 800;
  line-height: 38px;
  color: #c6d4e1;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  max-width: 400px;
  padding: 0 16px;
  margin-left: auto;
  margin-right: auto;
}
.mentorat_testimonials_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}
.mentorat_testimonials_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1c1c1e;
  padding: 18px 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
  max-width: 450px;
}
.mentorat_testimonials_item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}
.mentorat_testimonials_item-header img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
  object-fit: cover;
  border: 4px solid white;
}
.mentorat_testimonials_item-content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* font-style: italic; */
  margin: 0;
  color: #aec0d7;
  margin-bottom: 8px;
}
.mentorat_testimonials_item-header-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mentorat_testimonials_item-header-description span:first-of-type {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.mentorat_testimonials_item-header-description span:last-of-type {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.mentorat_testimonials_item-header-description span:last-of-type a {
  cursor: pointer;
  display: block;
  margin-top: 4px;
  color: var(--primary-color);
}
/* SERVICE SECTION  */
.mentorat_service {
  padding: 40px 8px 16px 8px;
}
.mentorat_service_title {
  font-size: 32px;
  font-weight: 800;
  background: linear-gradient(45deg, #8b79b9, #799ab9);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 32px;
  position: relative;
  display: block;
}
.mentorat_service_pricing-calendar-container {
  background: linear-gradient(100.55deg, #8b79b9 8.75%, #547ca1 57.85%);
  border-radius: 16px;
  padding: 4px;
  margin-bottom: 32px;
  display: block;
}
.pricing-calendar_content {
  background-color: #1c1c1e;
  border-radius: 14px;
}
.pricing-calendar_pricing {
  padding: 24px 16px;
}
.pricing-calendar_pricing-intro {
  color: var(--blue-text-color);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 16px;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.pricing-calendar_pricing-intro span:last-of-type {
  background: linear-gradient(
    90.8deg,
    #d7ceae 5.74%,
    #c8b366 29.08%,
    #edd88d 61.34%,
    #e2c08e 81.87%,
    #d7ceae 99.61%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 0px;
}
.pricing-calendar_title {
  color: var(--blue-text-color);
  font-weight: 700;
  display: block;
  font-size: 24px;
}
.pricing-calendar_price {
  color: white;
  font-weight: 700;
  font-size: 80px;
  display: block;
}
.pricing-calendar_price span {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-left: 8px;
  color: var(--blue-text-color);
}
.pricing-calendar_duration {
  color: var(--blue-text-color);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 24px;
  display: block;
}
.pricing-calendar_bulletpoints {
  display: flex;
  flex-direction: column;
}
.pricing-calendar_bulletpoints span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #94c69c;
  display: flex;
  align-items: center;
}
.pricing-calendar_bulletpoints span::before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  background-size: contain;
  background-image: url("./../assets/mentorat/check.svg");
}
.pricing-calendar_divider {
  height: 2px;
  width: calc(100% - 32px);
  margin: auto;
  background: linear-gradient(100.55deg, #8b79b9 8.75%, #547ca1 57.85%);
  border-radius: 50px;
  margin-bottom: 24px;
}
.pricing-calendar_calendar {
  padding: 0 16px;
}
.pricing-calendar_calendar-title {
  transition: all 200ms ease-in-out;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--blue-text-color);
  margin-bottom: 16px;
  display: block;
}
.pricing-calendar_calendar_hours {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
}
.pricing-calendar_calendar_hours div {
  transition: all 200ms ease-in-out;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 16px;
  border-radius: 8px;
  color: #4ab3d9;
  background-color: #081e26;
  border: 1px solid #1e718f65;
  font-size: 20px;
  font-weight: 600;
}
.pricing-calendar_calendar_hours
  div:hover:not(.pricing-calendar_calendar_hours--selected) {
  filter: brightness(1.2);
}
.pricing-calendar_calendar_hours--selected {
  background-color: #4ab3d9 !important;
  color: #081e26 !important;
}
.pricing-calendar_submit-control {
  padding: 24px 0 16px 0;
}
.pricing-calendar_submit-control_button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #53799d;
  background-color: #53799d;
  font-size: 16px;
  font-weight: 600;
  color: white;
  transition: all 250ms;
}
.pricing-calendar_submit-control_button:disabled {
  opacity: 0.2;
}
.pricing-calendar_calendar-days {
  transition: all 200ms ease-in-out;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
}
.pricing-calendar_calendar_day {
  cursor: pointer;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: black;
  width: 100%;
  padding: 8px;
  align-items: center;
  border-radius: 8px;
  color: #c285e5;
  background-color: #1b0826;
  border: 1px solid #902bca5a;
}
.pricing-calendar_calendar_day:hover:not(
    .pricing-calendar_calendar_day--selected
  ):not(.pricing-calendar_calendar_day--disabled) {
  filter: brightness(1.2);
}
.pricing-calendar_calendar_day--selected {
  background-color: #c285e5 !important;
  color: #1b0826 !important;
}
.pricing-calendar_calendar_day--disabled {
  background-color: #1b0826 !important;
  color: #902bca5a !important;
  cursor: not-allowed;
  opacity: 0.4;
}
.pricing-calendar_calendar_day span:nth-of-type(1) {
  font-weight: 600;
  font-size: 14px;
}
.pricing-calendar_calendar_day span:nth-of-type(2) {
  font-weight: 600;
  font-size: 22px;

  line-height: 14px;
}
.pricing-calendar_calendar_day span:nth-of-type(3) {
  font-weight: 600;
  font-size: 16px;
}
/* .pricing-calendar_availabilities {
  display: grid;
}
.pricing-calendar_availabilities_item {
  display: flex;
}
.pricing-calendar_submit-control {
  padding: 16px 0;
}
.pricing-calendar_submit-control_button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #53799d;
  background-color: #53799d;
  font-size: 16px;
  font-weight: 600;
  color: white;
}
.pricing-calendar_availabilities_item {
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border: 1px solid #53799d;
  border-radius: 4px;
  margin-bottom: 12px;
}
.pricing-calendar_availabilities_item-date {
  color: var(--blue-text-color);
  font-weight: 600;
  padding-left: 16px;
}
.pricing-calendar_availabilities_hours {
  height: 100%;
  display: flex;
  align-items: center;
}
.pricing-calendar_availabilities_hours-first {
  color: #c285e5;
  background-color: #1b0826;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-left: 1px solid #902bca5a;
}
.pricing-calendar_availabilities_hours-second {
  color: #4ab3d9;
  background-color: #081e26;
    border-left: 1px solid #1e718f65;
  height: 100%;
  display: flex;
  align-items: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 16px;
} */
.mentorat_service_more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mentorat_service_more span:first-of-type,
.mentorat_service_more span:nth-of-type(2) {
  display: block;
  font-size: 18px;
  font-weight: 800;
  background: linear-gradient(45deg, #8b79b9, #799ab9);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  position: relative;
  max-width: 400px;
  padding: 0 16px;
}
.mentorat_service_more span:last-of-type {
  padding: 24px 16px;
  color: var(--blue-text-color);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.mentorat_service_more span:last-of-type a {
  color: var(--blue-text-color);
  font-weight: 700;
}
/* POLICY SECTION */
.mentorat_policy-container {
  background: linear-gradient(
    237.99deg,
    rgba(93, 62, 214, 0.28125) 22.67%,
    rgba(122, 128, 186, 0.07) 73.46%
  );
}
.mentorat_policy {
  color: #c6d4e1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.mentorat_policy_col {
  flex: 1;
}
.mentorat_policy_col_title {
  font-weight: 700;
  font-size: 20px;
  color: #c6d4e1;
  margin-bottom: 24px;
  display: block;
}
.mentorat_policy_col_item_title {
  font-weight: 700;
  font-size: 16px;
  display: block;
}
.mentorat_policy_col_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.mentorat_policy_col_item_content {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 24px;
}
.copyright {
  font-size: 12px;
  font-weight: 500;
  color: #c6d4e1;
  text-align: center;
  padding: 24px 0;
  display: block;
}
/* Small Screen */
@media screen and (min-width: 500px) {
  /* HERO SECTION */
  .mentorat_hero_pic {
    background: url("./../assets/mentorat/hugo-1.png") no-repeat 100% bottom;
    background-size: 570px;
    max-width: 512px;
    margin-left: calc(100% - 350px);
  }
  .mentorat_hero_pic--code {
    background: url("./../assets/mentorat/hugo-1-code.png") no-repeat 100%
      bottom;
    background-size: 570px;
    max-width: 512px;
    margin-left: calc(100% - 350px);
  }
  .mentorat_hero_intro {
    font-size: 16px;
    margin-bottom: 18px;
  }
  .mentorat_hero_title {
    font-size: clamp(40px, 8vw, 50px);
    line-height: clamp(40px, 8vw, 50px);
    max-width: 100%;
    max-width: 740px;
  }
  .mentorat_hero_subtitle {
    font-size: 18px;
    max-width: 450px;
    margin-bottom: 24px;
  }
  .mentorat_hero_description {
    max-width: 80%;
  }
  .mentorat_hero_cta-section {
    max-width: 740px;
  }
  .mentorat_hero_description-container {
    max-width: 450px;
  }
  .mentorat_hero_cta-container {
    max-width: 450px;
  }
  /* EXPERT SECTION */
  .mentorat_expert_title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
    font-size: clamp(40px, 8vw, 50px);
    line-height: clamp(40px, 8vw, 50px);
  }
  .mentorat_expert_item {
    display: flex;
    flex-direction: row;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 64px;
    align-items: center;
    margin-bottom: 64px;
  }
  .expert-item-content {
    margin-left: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .mentorat_expert_item span {
    font-size: 20px;
  }
  .mentorat_expert_item p {
    text-align: start;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .mentorat_expert_item img {
    width: 100px;
    margin-bottom: 0px;
  }

  /* PROBE SECTION  */
  .mentorat_probe {
    padding-top: 56px;
  }
  .mentorat_probe_title {
    font-size: clamp(40px, 8vw, 50px);
    line-height: clamp(40px, 8vw, 50px);
    max-width: 100%;
    max-width: 740px;
    margin-bottom: 32px;
  }
  .mentorat_probe_illustrations--fb {
    top: -7%;
    left: -6vw;
    max-height: 200px;
    max-width: 200px;
  }
  .mentorat_probe_illustrations {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  .mentorat_probe_illustrations--html {
    position: absolute;
    max-height: 250px;
    max-width: 250px;
    top: 50%;
    right: -8vw;
    animation: logosAnimation 2s ease-in-out infinite alternate;
  }
  .mentorat_probe_illustrations-bottom--tw {
    display: none;
  }
  .mentorat_probe_illustrations-bottom--js {
    display: none;
  }
  .mentorat_probe_content {
    margin-right: 1vw;
  }
  .mentorat_probe_content span {
    font-size: clamp(16px, 3vw, 24px) !important;
    line-height: inherit !important;
  }
  .mentorat_probe_content p {
    font-size: clamp(14px, 2vw, 18px) !important;
    line-height: inherit !important;
    max-width: 50vw !important;
  }
  .mentorat_probe_illustrations-bottom {
    height: 32px !important;
  }
  .mentorat_probe_illustrations-bottom--hugo {
    max-height: 570px;
    left: -275px !important;
  }
  /* ADVANTAGES SECTION  */
  .mentorat_advantages_content {
    display: grid;
  }
  .mentorat_advantages_title {
    font-size: clamp(40px, 8vw, 50px);
    max-width: 100%;
    margin: 16px 0 56px 0;
  }
  .mentorat_advantages_item {
    max-width: 500px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .mentorat_advantages_item span {
    font-size: clamp(18px, 4vw, 22px);
    line-height: inherit;
  }
  .mentorat_advantages_item p {
    font-size: clamp(14px, 3vw, 18px);
    line-height: inherit;
  }
  /* TESTIMONIALS SECTION  */
  .mentorat_testimonials {
    padding-top: 48px;
    padding-bottom: 40px;
  }
  .mentorat_testimonials_title {
    font-size: clamp(40px, 8vw, 50px);
    line-height: clamp(40px, 8vw, 50px);
    max-width: 100%;
    max-width: 740px;
    margin-bottom: 48px;
  }
  .mentorat_testimonials_item {
    max-width: 670px;
  }
  /* SERVICE SECTION  */
  .mentorat_service_title {
    font-size: clamp(40px, 8vw, 50px);
    line-height: clamp(40px, 8vw, 50px);
    max-width: 100%;
    max-width: 740px;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
  }
  .mentorat_service_more {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 16px !important;
  }
  .mentorat_service_more span:nth-of-type(1),
  .mentorat_service_more span:nth-of-type(2) {
    font-size: clamp(18px, 4vw, 32px);
    max-width: 100%;
  }
  .mentorat_service_more span:nth-of-type(3) {
    font-size: clamp(14px, 2.5vw, 20px);
  }
}
/* Small Screen - Custom 550 */
@media screen and (min-width: 550px) {
  /* HERO SECTION */
  .mentorat_hero_description {
    max-width: 90%;
  }
}
/* Small Screen - Custom 600 */
@media screen and (min-width: 600px) {
  /* HERO SECTION */
  .mentorat_hero_pic--code {
    opacity: 1 !important;
  }
  .mentorat_hero_subtitle {
    font-size: 18px;
    max-width: 500px;
    margin-bottom: 24px;
  }
}
/* Medium Screen */
@media screen and (min-width: 700px) {
  /* EXPERT SECTION */
  .mentorat_expert_title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
    font-size: clamp(40px, 8vw, 50px);
    line-height: clamp(40px, 8vw, 50px);
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }
  .mentorat_expert_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 737px;
    margin: auto;
  }
  .mentorat_expert_item {
    flex-direction: column;
    padding: 0 32px;
    flex: 1;
  }
  .expert-item-content {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mentorat_expert_item span {
    font-size: 22px;
  }
  .mentorat_expert_item p {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 0;
    font-size: 16px;
  }
  .mentorat_expert_item img {
    width: 120px;
    height: 120px;
    margin-bottom: 32px;
  }

  /* PROBE SECTION */
  .mentorat_probe_content {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mentorat_probe_illustrations--html {
    top: 350px !important;
  }
  /* ADVANTAGES SECTION  */
  .mentorat_advantages_title {
    margin-top: 32px;
    margin-bottom: 80px;
  }
  .mentorat_advantages_content {
    display: grid;
    column-gap: 32px;
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }
}
/* Medium Screen - Custom 750*/
@media screen and (min-width: 750px) {
  /* HERO SECTION */
  .mentorat_hero_pic {
    background: url("./../assets/mentorat/hugo-1.png") no-repeat right bottom;
    background-size: 570px;
    max-width: 512px;
    margin-left: 52%;
  }
  .mentorat_hero_pic--code {
    background: url("./../assets/mentorat/hugo-1-code.png") no-repeat right
      bottom;
    background-size: 570px;
    max-width: 512px;
    margin-left: 52%;
  }
  /* POLICY SECTION  */
  .mentorat_policy {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    gap: 24px;
  }
  /* SERVICE SECTION  */
  .mentorat_service_pricing-calendar-container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  .pricing-calendar_content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
    padding: 32px 0;
    justify-content: space-between;
    align-items: stretch;
  }
  .pricing-calendar_content div:not(.lds-ring):not(.lds-ring__circle) {
    width: 100%;
    max-width: 430px;
  }
  .pricing-calendar_divider {
    width: 2px !important;
    margin: 0;
    height: inherit;
  }
  .pricing-calendar_calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pricing-calendar_calendar_day span:nth-of-type(1) {
    font-weight: 600;
    font-size: 10px;
  }
  .pricing-calendar_calendar_day span:nth-of-type(2) {
    font-weight: 600;
    font-size: 14px;
    line-height: 8px;
  }
  .pricing-calendar_calendar_day span:nth-of-type(3) {
    font-weight: 600;
    font-size: 10px;
  }
  .pricing-calendar_calendar_hours div {
    width: 100%;
    text-align: center;
    padding: 8px;
    border-radius: 8px;
    color: #4ab3d9;
    background-color: #081e26;
    border: 1px solid #1e718f65;
    font-size: 14px;
    font-weight: 600;
  }
  .pricing-calendar_calendar-title {
    /* font-size: 12px; */
  }
  .pricing-calendar_submit-control {
    padding: 24px 0 0px 0;
  }
  .pricing-calendar_pricing {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .pricing-calendar_duration,
  .pricing-calendar_pricing-intro {
    margin-bottom: 0;
  }
  .pricing-calendar_bulletpoints span {
    font-size: 16px;
  }
  .pricing-calendar_bulletpoints span::before {
    height: 16px;
    width: 16px;
  }
}
/* Large Screen Custom 886 */
@media screen and (min-width: 900px) {
  .pricing-calendar_content {
    justify-content: center;
    gap: 32px;
  }
}

/* Large Screen */
@media screen and (min-width: 1104px) {
  /* MAIN */
  .mentorat_wide-bg {
    height: 643px;
    background: linear-gradient(-120deg, #27b2ed50, #7a80ba12);
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;
    transition: all 0ms ease-in-out;
    z-index: -1;
    overflow: visible;
    padding-bottom: 16px;
    position: absolute;
  }
  /* HERO SECTION */
  .mentorat_hero {
    background-size: cover;
    align-items: start;
    padding-left: 56px;
    padding-top: 32px;
    max-width: 1200px;
    margin: 0 auto;
    background: none;
  }
  .mentorat_hero_intro {
    font-size: 14px;
    margin-bottom: 32px;
    text-align: left;
  }
  .mentorat_hero_title {
    font-size: 60px;
    line-height: 64px;
    text-align: left;
    margin-bottom: 24px;
  }
  .mentorat_hero_subtitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    max-width: 100%;
    padding: 0;
  }
  .mentorat_hero_description-section {
    width: 100%;
    position: relative;
    max-width: 100%;
    padding: 0;
  }
  .mentorat_hero_description-container {
    width: 100%;
    max-width: 616px;
    padding: 0;
  }
  .mentorat_hero_description {
    font-size: 16px;
    font-weight: 500;
    color: var(--blue-text-color);
    max-width: 100%;
    margin: 0;
    margin-bottom: 40px;
  }
  .mentorat_hero_cta-container {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .mentorat_hero_cta_label {
    color: #6caae0;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 12px;
    display: block;
  }
  .mentorat_hero_cta_label--secondary {
    color: #a691e8;
  }
  .mentorat_hero_cta_next-session-container {
    display: flex;
    margin-bottom: 16px;
  }
  .mentorat_hero_cta_next-session {
    height: 51px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #081826;
    color: #6caae0;
    font-weight: 600;
    border: 1px solid #53799d;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom: 1px solid #53799d;
    border-right: none;
    border-bottom-left-radius: 8px;
    text-align: center;
  }
  .mentorat_hero_cta-container button {
    width: 100%;
    height: 51px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border: 1px solid #53799d;
    background-color: #53799d;
    font-size: 16px;
    font-weight: 600;
    color: white;
    border-top-right-radius: 8px;
  }
  .mentorat_hero_cta-container button:first-of-type {
    margin-bottom: 12px;
  }
  .mentorat_hero_cta_button--secondary {
    background-color: #0f0826 !important;
    color: #a691e8 !important;
    border: 1px solid #a691e8 !important;
    border-radius: 8px !important;
  }
  .mentorat_hero_pic {
    background: url("./../assets/mentorat/hugo-1.png") no-repeat right bottom;
    background-size: 850px;
    max-width: 750px;
    margin-left: 52%;
  }
  .mentorat_hero_pic--code {
    background: url("./../assets/mentorat/hugo-1-code.png") no-repeat right
      bottom;
    background-size: 850px;
    max-width: 750px;
    margin-left: 52%;
  }
  /* EXPERT SECTION */
  .mentorat_expert_content {
    display: flex;
    max-width: 1200px;
  }
  .mentorat_expert_title {
    max-width: 100%;
  }
  /* PROBE SECTION  */
  .mentorat_probe_content {
    margin: auto;
    justify-content: center;
    align-items: center !important;
    max-width: 620px;
  }
  .mentorat_probe_content span {
    font-size: clamp(14px, 3vw, 24px) !important;
    line-height: inherit !important;
    text-align: center !important;
  }
  .mentorat_probe_content p {
    font-size: clamp(12px, 2vw, 18px) !important;
    line-height: inherit !important;
    max-width: 50vw !important;
    text-align: center !important;
  }
  .mentorat_probe_illustrations-bottom--tw {
    display: block;
    max-height: 350px !important;
    max-width: 350px !important;
    top: -250px;
  }
  .mentorat_probe_illustrations-bottom--js {
    display: block;
    top: -650px;
  }
  /* ADVANTAGES SECTION */
  .mentorat_advantages_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    max-width: 1200px;
  }
  /* TESTIMONIALS SECTION  */
  .mentorat_testimonials_list {
    display: flex;
    flex-direction: row;
    gap: 32px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }
  .mentorat_testimonials_item {
    flex: 1;
  }
}
/* MISC */
.bubble-1 {
  top: 0;
  left: -20%;
}
.bubble-2 {
  bottom: 0;
  right: -20%;
}
.mentorat button {
  cursor: pointer;
}
.mentorat button:hover {
  filter: brightness(1.1);
}
.mentorat button:active {
  filter: brightness(0.9);
}
/* ANIMATIONS */
@keyframes picCodeAnimation {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -15px, 0);
  }
}
@keyframes logosAnimation {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -10px, 0);
  }
}
