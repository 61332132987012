:root,
:root.light {
  /* --bg-color: #403f47; */
  --bg-color: #eff1f2;
  --dark-bg-color: #222222;
  --primary-color: #7766ae;
  --secondary-color: #14b8a6;
  --secondary-color-light: #18f9df;
  --text-color: #484848;
  --grey-color: #707070;
  --success-color: #22c55e;
  --success-color-light: #7fffae;
  --skeleton-color: #f1f2f3;
  --danger-color: #ef4444;
  --danger-color-light: #ffa4a4;
  --gold-color: rgb(235, 210, 152);
  --gold-color-light: rgb(255, 245, 191);
  --gold-color-dark: rgb(115, 102, 73);
  --current-scheme: light;
  --elevation: #eff1f2;
}
:root.dark {
  --current-scheme: dark;
  --bg-color: #000000;
  --primary-color: #8b7dba;
  --text-color: white;
  --elevation: #1c1c1e;
}
* {
  box-sizing: border-box;
  font-family: "Inter";
  color-scheme: var(--current-scheme);
}

body {
  overflow-y: scroll;
  background-color: var(--bg-color);
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[data-color-scheme="dark"] {
  --bg-color: #000000;
  --primary-color: #7766ae;
  --secondary-color: #14b8a6;
  --secondary-color-light: #18f9df;
  --text-color: #484848;
  --grey-color: #707070;
  --success-color: #22c55e;
  --success-color-light: #7fffae;
  --skeleton-color: #f1f2f3;
  --danger-color: #ef4444;
  --danger-color-light: #ffa4a4;
  --gold-color: rgb(235, 210, 152);
  --gold-color-light: rgb(255, 245, 191);
  --gold-color-dark: rgb(115, 102, 73);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input {
  border: none;
  background-image: none;
  /* background-color: transparent; */
  background-color: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.skeleton {
  animation: skeleton-loading 500ms linear infinite alternate;
  width: 100%;
  background-color: var(--skeleton-color);
  border-radius: 0.5rem;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 85%);
  }
  100% {
    background-color: hsl(200, 20%, 90%);
  }
}
