.confirm-curtain {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 4;
  top: 0;
  left: 0;
}
.confirm-prompt {
  position: fixed;
  width: calc(100% - 6rem);
  border-radius: 0.5rem;
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: #222222;
  backdrop-filter: blur(5px);
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  left: 0;
  right: 0;
  max-width: 25rem;
  border-top: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 500ms ease;
  animation: enteringPrompt 350ms ease forwards;
}
@keyframes enteringPrompt {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}
.confirm-prompt--pending {
  background-color: var(--primary-color);
  color: white !important;
  border-color: white !important;
}
.confirm-prompt--gain {
  background-color: #222222;
  color: var(--gold-color) !important;
  border-color: var(--gold-color) !important;
}
.confirm-prompt--no-gain {
  background-color: white;
  color: var(--text-color) !important;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.confirm-prompt__title {
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  transition: all 1000ms ease;
  animation: fadeIn 500ms ease forwards;
}
.confirm-prompt__info-content {
  font-weight: 400;
  /* padding: 3rem; */
  padding: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  flex: 1;
  font-size: 0.875rem;
  text-align: center;
  transition: all 1000ms ease;
  animation: fadeIn 500ms ease forwards;
  min-height: 72px;
}
.confirm-prompt__info-content span {
  font-weight: 900;
  /* transition: all 1000ms ease; */
  font-size: 1.5rem;
}
.confirm-prompt__controls {
  display: flex;
  justify-content: flex-end;
  transition: all 500ms ease;
  animation: fadeIn 250ms ease forwards;
  min-height: 45px;
}

.confirm-prompt__controls button {
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0.75rem;
  font-size: 1rem;
  border-top: 1px solid;
  border-color: inherit;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-weight: 600;
  transition: all 150ms ease-in-out color 250ms ease-in-out;
  /* animation: fadeIn 500ms ease forwards; */
}
.confirm-prompt__controls button:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
