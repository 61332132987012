.base-input {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
}
.base-input--no-margin {
  margin-bottom: 0;
}
.base-input label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
  color: var(--text-color);
}
.base-input input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  /* background-color: rgba(255, 255, 255, 0.05); */
  outline-color: var(--primary-color);
  border-radius: 0.25rem;
  color: var(--text-color);
  border: 1px solid gray;
  height: 2.5rem;
}
.base-input input:disabled {
  opacity: 0.2;
}
.base-input--with-action input {
  padding-right: 3rem;
}
