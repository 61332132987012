.profile-detail-page {
  width: 100%;
  padding: 1rem;
  display: grid;
  /* grid-template-columns: repeat(1, 1); */
  grid-gap: 1rem;
  max-width: 1122px;
  margin: auto;
  grid-template-areas:
    "identity"
    "about"
    "skills";
}
@media screen and (min-width: 800px) {
  .profile-detail-page {
    margin: 1rem auto;
    grid-template-columns: repeat(2, calc((100% - 1rem) / 2));
    grid-template-areas:
      "identity skills"
      "about skills"
      "empty skills";
  }
  .profile-detail-page section {
    align-self: start;
  }
}
.profile-detail-page__member-card {
  position: relative;
  height: 164px;
  width: 100%;
  margin-top: 3rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.12);
}
.profile-detail-page__member-card > img,
.profile-detail-page__member-card > .pp-placeholder {
  height: 128px;
  width: 128px;
  border: 0.25rem white solid;
  border-radius: 0.25rem;
  margin-right: 1rem;
  object-fit: cover;
  position: absolute;
  top: -3rem;
}
.profile-detail-page__member-card__socials {
  display: flex;
  position: absolute;
  top: -3rem;
  right: 0;
}
.profile-detail-page__member-card__socials__display {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 0.25rem;
  background-color: white;
  border: 1px solid var(--bg-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  color: var(--grey-color);
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 200ms ease;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 2px 0px rgb(227, 227, 227);
  padding: 0 0.25rem;
  margin-left: 1rem;
  cursor: pointer;
}
.profile-detail-page__member-card__socials__display span {
  padding: 0 0.5rem;
}
.profile-detail-page__member-card__identity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 144px;
}
.profile-detail-page__member-card__identity span:first-of-type {
  color: var(--text-color);
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.5rem;
  /* margin-top: 0.825rem; */
  text-transform: capitalize;
}
.profile-detail-page__member-card__identity span:nth-of-type(2) {
  color: var(--text-color);
  opacity: 0.7;
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: capitalize;
}
.profile-detail-page__member-card__identity span:last-of-type {
  color: var(--text-color);
  opacity: 0.6;
  font-weight: 700;
  font-size: 0.825rem;
}
.profile-detail-page__member-card__divider {
  width: 100%;
  height: 2px;
  background-color: var(--bg-color);
  margin: 1rem 0;
}
.profile-detail-page__member-card__sub-infos {
  padding: 0.25rem;
  background-color: var(--bg-color);
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23dbdae0' fill-opacity='0.35' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 3rem;
  background-position: center;
}
.profile-detail-page__member-card__sub-infos span {
  flex: 1;
  text-align: center;
  color: var(--text-color);
  opacity: 0.7;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-detail-page__member-card__sub-infos span svg {
  margin-right: 0.5rem;
}
.profile-detail-page__member-card__sub-infos span:nth-of-type(2) {
  border: 2px solid white;
  border-top: none;
  border-bottom: none;
}
.profile-detail-page__card {
  position: relative;
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.12);
}
.profile-detail-page__card h2 {
  font-size: 1.125rem;
  color: var(--text-color);
  margin-top: 0;
  font-weight: 900;
}
.profile-detail-page__card h3 {
  font-size: 0.875rem;
  color: var(--text-color);
  opacity: 0.8;
  margin-top: 0;
  font-weight: 700;
}
.profile-detail-page__card p {
  font-size: 0.875rem;
  color: var(--text-color);
  opacity: 0.8;
  margin-top: 0;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.profile-detail-page__card__skills {
  margin-bottom: 1rem;
  display: grid;
  margin: 1rem 0;
  grid-template-columns: repeat(2, calc((100% - 0.5rem) / 2));
  grid-gap: 0.25rem 0.5rem;
}
@media screen and (min-width: 950px) {
  .profile-detail-page__card__skills {
    grid-template-columns: repeat(3, calc((100% - 1rem) / 3));
  }
}
.profile-detail-page__card__skills:last-of-type {
  margin-bottom: 0;
}
.profile-detail-page__card__skill-item {
  height: 2rem;
  min-height: 2rem;
  border-radius: 0.25rem;
  background-color: white;
  border: 1px solid var(--bg-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 200ms ease;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 2px 0px var(--bg-color);
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--text-color);
}
