.pp-placeholder {
  height: 104px;
  width: 104px;
  min-height: 104px;
  min-width: 104px;
  border: 0.25rem white solid;
  border-radius: 0.25rem;
  margin-right: 1rem;
  object-fit: cover;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.pp-placeholder span {
  opacity: 0.4;
}
