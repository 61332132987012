.welcome-counter {
  margin: 1rem;
  /* padding: 0.5rem 1rem; */
  font-weight: 600;
  margin-bottom: 0;
  border-radius: 0.25rem;
  /* background-color: white; */
  color: var(--text-color);
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */

  display: flex;
  flex-direction: column;
}
.welcome-counter__count {
  color: var(--primary-color);
  font-weight: bold;
}

.welcome-counter__sub-counter {
  background-color: var(--primary-color);
  height: 100%;
  /* border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; */
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25rem 1rem;
}
.welcome-counter__sub-counter span {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.welcome-counter__sub-counter span span:first-of-type {
  color: var(--bg-color);
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 0.375rem;
}
.welcome-counter__sub-counter span span:last-of-type {
  color: var(--bg-color);

  font-size: 0.75rem;
}
.welcome-counter__contest-cta {
  flex: 1;
  font-size: 0.875rem;
  padding: 0.4rem 1rem;
  line-height: 1.25rem;
  color: var(--text-color);
}

@media screen and (min-width: 910px) {
  .welcome-counter {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: 800px;
    font-size: 1.1rem;
  }
  .welcome-counter__sub-counter {
    background-color: var(--primary-color);
    height: 100%;
    /* border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0rem; */
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    min-width: 153px;
  }
  .welcome-counter__contest-cta {
    flex: 1;
    font-size: 0.75rem;
    padding: 0 1rem;
    line-height: 1.125rem;
    color: var(--text-color);
  }
  .welcome-counter {
    margin: 1rem;
    /* padding: 0.5rem 1rem; */
    font-weight: 600;
    margin-bottom: 0;
    border-radius: 0.25rem;
    /* background-color: white; */
    color: var(--text-color);
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    min-height: 65px;
    max-height: 65px;
    display: flex;
  }
}
@media screen and (min-width: 1060px) {
  .welcome-counter__contest-cta {
    flex: 1;
    font-size: 0.875rem;
    padding: 0 1rem;
    line-height: 1.25rem;
    color: var(--text-color);
  }
}
@media screen and (min-width: 1250px) {
  .welcome-counter__contest-cta {
    flex: 1;
    font-size: 1rem;
    padding: 0 1rem;
    line-height: 1.5rem;
    color: var(--text-color);
  }
}
.welcome-counter__contest-cta span:first-of-type {
  color: var(--primary-color);
  font-weight: bold;
}
.welcome-counter__contest-cta span {
  font-weight: 500;
}
.welcome-counter__contest-cta a {
  font-weight: 600;
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  display: block;
}
.welcome-counter__contest-cta a:hover {
  filter: brightness(1.375);
}
.welcome-counter__contest-cta__gifts {
  font-style: italic;
  font-weight: 500 !important;
  color: rgb(19, 199, 163) !important;
}
@media screen and (min-width: 910px) {
  .welcome-counter__contest-cta a {
    display: inline;
  }
}
