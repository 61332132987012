.base-toggle {
  position: relative;
}

.base-toggle__checkbox {
  cursor: pointer;
  opacity: 1;
  position: absolute;
  top: 1px;
  left: 0;
  width: 50px;
  height: 28px;
  margin: 0;
  opacity: 0;
}
.base-toggle__toggler {
  background-color: whitesmoke;
  height: 30px;
  width: 50px;
  border-radius: 60px;
  border: 1px solid #e4e4e4;
  transition: background-color 0.2s ease;
}
.base-toggle__toggler__dot {
  pointer-events: none;
  content: "";
  background-color: white;
  height: 28px;
  width: 28px;
  border-radius: 60px;
  border: 1px solid lightgray;
  transform: translateX(-2px);
  transition: transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.base-toggle__checkbox:checked + .base-toggle__toggler {
  background-color: var(--success-color);
  border: 1px solid var(--success-color);
}
.base-toggle__checkbox:checked
  + .base-toggle__toggler
  > .base-toggle__toggler__dot {
  transform: translateX(22px);
}
