.mouse-info {
  position: fixed;
  padding: 0.25rem 0.75rem;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
  border-radius: 3rem;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.4);
  transform: translateX(-100%) translateY(-100%);
  font-size: 0.825rem;
}
