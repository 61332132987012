.load-more {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  /* max-width: 500px; */
  padding: 1rem;
  /* padding-bottom: 1.5rem; */
  text-align: center;
  font-weight: bold;
  opacity: 1;
  transition: opacity 250ms ease;
  color: rgb(157, 157, 157);
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(15px);
}
.load-more__button {
  width: 130px;
}
.search-actions {
  /* margin: 1rem; */
  padding: 1rem 1rem 0 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  height: 100%;
  /* background-color: red; */
}
.search-actions__pseudo-search {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 8;
}
.skill-selector {
  flex: 2;
}
.skill-selector label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
  color: var(--text-color);
}
.skill-selector select {
  cursor: pointer;
  padding: 8px;
  height: 2.5rem;
  font-size: 16px;
  background-color: white;
  outline-color: var(--primary-color);
  border-radius: 0.25rem;
  color: var(--text-color);
  border: 1px solid gray;
  max-width: 120px;
}
.skill-selector select option {
  background-color: white;
}
@media screen and (min-width: 910px) {
  .top-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .search-actions {
    width: 100%;
    max-width: 400px;
  }
}
.profile-editor__not-visible {
  background-color: rgb(254, 232, 232);
  border: 1px solid red;
  padding: 0.5rem 1rem;
  color: red;
  border-radius: 0.375rem;
  font-weight: 500;
}
