.base-text-area {
  width: 100%;
  margin-bottom: 1rem;
}
.base-text-area label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
}
.base-text-area textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  outline-color: var(--primary-color);
  border-radius: 0.25rem;
  color: var(--text-color);
  border: 1px solid gray;
  resize: none;
  white-space: pre-wrap;
}
