.skill-icon {
  width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  border-radius: 0.25rem;
  /* background-color: rgba(255, 255, 255, 0.025); */
  background-color: white;
  /* border: solid var(--grey-color) 1px; */
  border: 1px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--grey-color);
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 200ms ease;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 2px 0px var(--bg-color);
}
.skill-icon:not(.skill-icon--selected):hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}
.skill-icon__label {
  display: none;
}
.skill-icon:hover > .skill-icon__label {
  display: block;
  position: absolute;
  top: -100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 1rem;
  z-index: 2;
}
.skill-icon--mini {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
}
.skill-icon--maxi {
  height: calc(3.5rem - 1px);
  width: calc(3.5rem - 1px);
  min-height: calc(3.5rem - 1px);
  min-width: calc(3.5rem - 1px);
  border: none;
  border-radius: 0.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.skill-icon--inList {
  height: calc(2rem - 1px);
  width: calc(2rem - 1px);
  min-height: calc(2rem - 1px);
  min-width: calc(2rem - 1px);
  border: none;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: white;
  border-right: 1px solid var(--bg-color);
  margin-right: 0.5rem;
}
.skill-icon--maxi:not(.skill-icon--selected):hover {
  cursor: auto;
  background-color: rgba(255, 255, 255, 0.025);
}
.skill-icon--selected {
  border: solid cornflowerblue 1px;
  background-color: rgba(0, 106, 255, 0.109);
  box-shadow: 0px 2px 0px cornflowerblue;
}
.skill-icon--selected-main {
  border: solid var(--primary-color) 1px;
  background-color: rgba(144, 0, 255, 0.076);
  box-shadow: 0px 2px 0px var(--primary-color);
}

.skill-icon__star {
  position: absolute;
  bottom: -0.5rem;
}

.skill-icon__star {
  position: absolute;
  bottom: 0.125rem;
  left: 0.125rem;
}
