.profile-editor {
  width: 100%;
  padding: 1rem;
  max-width: 1122px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile-editor .start-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: calc(50% - 2rem);
}
.profile-editor .end-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: calc(50% - 2rem);
}
.profile-editor__element {
  position: relative;
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.12);
}
.profile-editor__element h2 {
  font-size: 1.125rem;
  color: var(--text-color);
  margin-top: 0;
  font-weight: 900;
}
.profile-editor__element h3 {
  font-size: 0.875rem;
  color: var(--text-color);
  opacity: 0.8;
  margin-top: 0;
  font-weight: 700;
}
.profile-editor__element p {
  font-size: 0.875rem;
  color: var(--text-color);
  opacity: 0.8;
  margin-top: 0;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.profile-editor__element__control {
  display: flex;
  justify-content: flex-end;
}
.geo-selector {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
}
.geo-selector select {
  width: 100%;
  cursor: pointer;
  padding: 8px;
  height: 2.5rem;
  font-size: 16px;
  background-color: white;
  outline-color: var(--primary-color);
  border-radius: 0.25rem;
  color: var(--text-color);
  border: 1px solid gray;
  -webkit-appearance: none;
  appearance: none;
}
.geo-selector select option {
  background-color: white;
}
.geo-selector::after {
  content: "⌄";
  font-size: 1.5rem;
  top: 24px;
  right: 1rem;
  position: absolute;
  color: var(--text-color);
}
.status-selector {
  display: flex;
  border: 1px solid gray;
  padding: 0.5rem 0;
  color: var(--text-color);
  border-radius: 90rem;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 1rem;
}
.status-selector__item {
  width: 33.3%;
  text-align: center;
  cursor: pointer;
  transition: all 250ms ease;
}
.status-selector__item:nth-of-type(2) {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}
.status-selector__item--active {
  color: var(--primary-color);
  font-weight: bold;
}
.profile-editor label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 16px;
  margin-bottom: 8px;
  color: var(--text-color);
}
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(2.5rem, 1fr));
  grid-column-gap: 0.2rem;
  grid-row-gap: 0.5rem;
  margin-bottom: 1rem;
}
.skills-grid--last {
  margin-bottom: 2rem;
}
.mini-label {
  opacity: 0.8;
  font-weight: 500;
}
.label-with-button {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-weight: 800 !important;
  margin-bottom: 1rem !important;
}
.reset-skills-button {
  cursor: pointer;
  transition: color 200ms ease-in-out;
}
.reset-skills-button:hover {
  color: var(--primary-color);
}
@media screen and (min-width: 800px) {
  .profile-editor {
    flex-direction: row;
  }
}
/* .profile-editor__member-card--skeleton {
  width: 100%;
  height: 13.5rem;
  background-color: var(--skeleton-color);
  border-radius: 0.5rem;
}
.profile-editor__contest--skeleton {
  height: 54px;
  width: 100%;
  background-color: var(--skeleton-color);
  border-radius: 0.5rem;
} */
.profile-editor__interests h2 {
  color: var(--primary-color);
  cursor: pointer;
  transition: all 250ms ease;
}
.profile-editor__interests h2:hover {
  filter: brightness(1.2);
}
.profile-editor__interests__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.profile-editor__interests__container__item {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  border-bottom: 1px solid rgb(226, 226, 226);

  padding-bottom: 0.5rem;
  justify-content: space-between;
}
.profile-editor__interests__container__item h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 16px;
}
.profile-editor__interests__container__item:first-of-type {
  border-top: 1px solid rgb(226, 226, 226);
  padding-top: 0.75rem;
  margin-top: 1rem;
}
.profile-editor__interests__container__item:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.profile-editor__interests__container__item__description {
  max-width: 313px;
}
.profile-editor__interests__container__item__description__action {
  display: flex;
  justify-content: flex-end;
}
.account-newsletter-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--text-color);
}
.account-newsletter-field label {
  font-weight: 500;
  margin-left: 16px;
  font-size: 14px;
  margin-bottom: 0;
}
.account-newsletter-field label span {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
}
