.newsletter-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--text-color);
  margin-bottom: 1rem;
}
.newsletter-field label {
  font-weight: 500;
  margin-left: 16px;
  font-size: 14px;
}
.newsletter-field label span {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
}
