.navbar {
  width: 100%;
  height: 3.5rem;
  background-color: var(--elevation);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 1;
  top: 0;
}

.navbar-content {
  height: calc(100% - 2px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: green; */
}
.navbar-decoration {
  height: 2px;
  background-color: var(--primary-color);
}
.navbar-content__logo {
  padding: 0.5rem 1rem;
  height: 100%;
}
.navbar-content__logo img {
  max-height: 100%;
  width: auto;
}
.navbar-content__actions {
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
.navbar-content__actions__join {
  cursor: pointer;
  font-weight: 600;
  line-height: 1rem;
  color: var(--primary-color);
  text-decoration: none;
  /* opacity: 0.5; */
  border: 2px solid var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 250ms ease;
}
.navbar-content__actions__members {
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: var(--primary-color);
  text-decoration: none;
  margin-right: 1.5rem;
}
/* .action--active {
  padding: 0.25rem 0;
  border-bottom: 2px solid var(--primary-color);
} */
.navbar-content__actions__join:hover {
  background-color: var(--primary-color);
  color: white;
}
