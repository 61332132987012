.signup-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.form-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-card {
  width: 100%;
  background-color: var(--bg-color);
  border-radius: 0.5rem;
  color: white;
}
.form-card__header {
  font-weight: bold;
  font-size: 1.25rem;
  background-color: var(--primary-color);
  padding: 0.5rem 1rem;
}
.form-card__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px;
  margin: auto;
}
.form-card__content__form {
  padding: 1rem;
  padding-top: 2rem;
}
.form-card__content__control {
  padding: 1rem;
  padding-top: 0;
}
.form-card__content__control__signin {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  color: var(--primary-color);
  text-decoration: none;
}
.illustration-section {
  display: none;
}

@media only screen and (min-width: 800px) and (min-height: 600px) {
  .login-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 56px);
  }

  .illustration-section {
    height: 100vh;
    min-width: 400px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .illustration-section__logo {
    border: solid var(--bg-color) 0.25rem;
    font-weight: bold;
    background-color: var(--primary-color);
    color: var(--primary-color);
    width: 256px;
    height: 80px;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  .illustration-section__logo__frame {
    padding: 0.25rem 0.9rem;
    background-color: var(--bg-color);
  }
  .signup-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
  .form-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .form-card {
    width: 380px;
    background-color: var(--bg-color);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 0.5rem;
  }
  .form-card__header {
    font-weight: bold;
    font-size: 1.25rem;
    background-color: var(--primary-color);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .form-card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form-card__content__form {
    padding: 1rem;
    padding-top: 2rem;
  }
  .form-card__content__control {
    padding: 1rem;
    padding-top: 0;
  }
  .form-card__content__control__signin {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: none;
  }
}
