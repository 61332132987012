.members-grid {
  display: grid;
  margin-top: 1rem;
  margin-bottom: 5rem;
  padding: 1rem;
  grid-template-columns: repeat(1, 100%);
  grid-gap: 1rem;
}
@media screen and (min-width: 800px) {
  .members-grid {
    grid-template-columns: repeat(2, calc((100% - 1rem) / 2));
  }
}
@media screen and (min-width: 1150px) {
  .members-grid {
    grid-template-columns: repeat(3, calc((100% - 2rem) / 3));
  }
}
@media screen and (min-width: 1550px) {
  .members-grid {
    grid-template-columns: repeat(4, calc((100% - 3rem) / 4));
  }
}
@media screen and (min-width: 1900px) {
  .members-grid {
    grid-template-columns: repeat(5, calc((100% - 4rem) / 5));
  }
}
@media screen and (min-width: 2700px) {
  .members-grid {
    grid-template-columns: repeat(6, calc((100% - 5rem) / 6));
  }
}
@media screen and (min-width: 3000px) {
  .members-grid {
    grid-template-columns: repeat(7, calc((100% - 6rem) / 7));
  }
}
