.grid-state {
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-state__text {
  color: var(--primary-color);
  font-weight: bold;
}
.grid-state__text--breath {
  animation: 500ms ease-in-out infinite alternate breath;
}
@keyframes breath {
  0% {
    filter: brightness(0.8);
  }
  50% {
    filter: brightness(1.2);
  }
  100% {
    filter: brightness(0.8);
  }
}
