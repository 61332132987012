.ended > div {
  background-color: rgb(254, 212, 212);
  border: 1px solid red;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  font-weight: 600;
  color: red;
}

.engage-page {
  padding-top: 1rem;
  padding: 1rem;
  color: #252525;
  max-width: 550px;
  margin: auto;
  scroll-behavior: smooth;
}
.engage-page button {
  cursor: pointer;
}
.engage-page > .hero {
  margin-top: 3rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.engage-page > .hero > article > h1 {
  font-size: 2.25rem;
  font-weight: 900;
  text-align: center;
  margin: 1.5rem 0 1rem 0;
}
.engage-page > .hero > article > h1 > span {
  color: var(--primary-color);
}
.engage-page > .hero > aside {
  width: 75%;
  max-width: 32rem;
}
.engage-page > .hero > article > p {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}
.engage-page > .hero > article > p > strong {
  color: var(--primary-color);
}
.engage-page > .hero > article > p > strong:nth-of-type(2) {
  color: goldenrod;
}
.engage-page > .hero > article > p > strong:nth-of-type(3) {
  color: var(--secondary-color);
}
.engage-page > .hero > article > .button-group {
  margin: 2rem 0;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}
.engage-page > .hero > article > .button-group > button {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  flex: 1;
  font-weight: 600;
  color: var(--primary-color);
  white-space: nowrap;
  font-size: 1.125rem;
}
.engage-page > .hero > article > .button-group > button:last-of-type {
  background-color: var(--primary-color);
  color: white;
}
aside > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.engage-page h2 {
  text-align: center;
  font-size: 1.75rem;
  color: var(--primary-color);
  font-weight: 700;
  margin: 3rem 0 3rem 0;
}
.how-it-works > div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
.how-it-works > div > article > aside {
  width: 100%;
  max-width: 12rem;
  margin: auto;
  margin-bottom: 1rem;
}
.how-it-works > div > article > p {
  font-weight: 500;
  text-align: center;
  font-size: 1.125rem;
}
.how-it-works > div > article > p > strong {
  color: var(--primary-color);
}
.what-to-win > .prizes {
  display: flex;
  flex-direction: column;
}
.what-to-win > .prizes > article {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 2rem;
}
.what-to-win > .prizes > article > footer > h3 {
  color: var(--primary-color);
  font-weight: 700;
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.what-to-win > .prizes > article > footer > h5 {
  margin-top: 0.25rem;
  color: #6f6f6f;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}
.what-to-win > .prizes > article > footer > h5 > a {
  color: var(--primary-color);
}
.prizes > article:first-of-type > header {
  background-color: var(--bg-color);
  padding: 1rem;
  border-radius: 0.375rem;
  border: white solid 0.5rem;
  display: flex;
  height: 13.5rem;
  align-items: center;
  gap: 1rem;
}
.prizes > article:first-of-type > header > aside {
  flex: 1;
  height: 100%;
}
.prizes > article:first-of-type > header > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.prizes > article:first-of-type > header > div > h4 {
  font-size: 2rem;
  color: var(--primary-color);
  margin: 0 0 0.5rem 0;
  font-size: 1.25rem;
  font-weight: 600;
}
.prizes > article:first-of-type > header > div > span {
  color: #6f6f6f;
  font-weight: 600;
  font-size: 0.825rem;
  color: var(--secondary-color);
}
.what-to-win > article:last-of-type {
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
}
.what-to-win > article:last-of-type > h4 {
  color: var(--primary-color);
  margin: 0 0 0.5rem 0;
  padding: 0.5rem;
  font-size: 1.24rem;
  font-weight: 700;
}
.what-to-win > article:last-of-type > ul {
  background-color: var(--bg-color);
  padding: 0.5rem 1rem;
  list-style-type: none;
  margin: 0;
  border-radius: 0.375rem;
}
.what-to-win > article:last-of-type > ul > li {
  margin: 0.5rem 0;
  font-weight: 600;
  /* font-style: italic; */
  color: var(--primary-color);
}
.what-to-win > article:last-of-type > ul > li:nth-of-type(1) {
  font-size: 1.25rem;
  margin-top: 0.5rem;
}
.what-to-win > article:last-of-type > ul > li:nth-of-type(2) {
  font-size: 1.175rem;
}
.what-to-win > article:last-of-type > ul > li:nth-of-type(3) {
  font-size: 1rem;
}
.what-to-win > article:last-of-type > ul > li:last-of-type {
  margin-bottom: 0.5rem;
}
.what-to-win > article:last-of-type > ul > li > strong {
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 800;
  display: inline-block;
}
.what-to-win {
  margin-bottom: 3rem;
}
.how-possible > h2 {
  margin-bottom: 2rem;
}
.how-possible > div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.how-possible > div > article > h3 {
  margin: 0 0 1.5rem 0;
  color: var(--primary-color);
}
.how-possible > div > article > p {
  font-weight: 500;
}
.how-possible > div:first-of-type {
  flex-direction: column-reverse;
}
.how-possible > div > aside {
  max-width: 15rem;
  margin: auto;
}
.how-to-join > div {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 2rem;
}
.how-to-join > div > aside {
  max-width: 15rem;
  margin: auto;
}
.how-to-join > div > article > h3 {
  color: var(--primary-color);
}
.how-to-join > div > article > h3 > span {
  background-color: white;
  display: inline-block;
  border: 3px solid var(--primary-color);
  text-align: center;
  font-size: 1.25rem;
  width: 2rem;
  height: 2rem;
  line-height: 1.625rem;
  border-radius: 32rem;
  font-weight: 800;
  margin-right: 0.75rem;
}
.how-to-join > div > article {
  font-weight: 500;
}
.how-to-join > div > article > p > small {
  font-style: italic;
  opacity: 0.8;
}
.faq-and-rules > div {
  max-width: 60rem;
  margin: auto;
  padding-bottom: 3rem;
}
.faq-and-rules > div > article > header {
  background-color: white;
  padding: 0.75rem 1rem;
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-and-rules > div > article:first-of-type > header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.faq-and-rules > div > article > header > h3 {
  margin: 0;
  font-size: 1rem;
  margin-right: 1rem;
}
.faq-and-rules > div > article > p {
  background-color: rgb(248, 248, 248);
  margin: 0;
  border: 1px solid white;
  padding: 1rem;
  font-size: 0.875rem;
  border-bottom: 1px solid var(--primary-color);
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .engage-page {
    max-width: 1044px;
    padding-top: 2rem;
  }
  .engage-page > .hero {
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 8rem;
    margin-top: 4rem;
  }
  .engage-page > .hero > article {
    flex: 5;
  }
  .engage-page > .hero > article > h1 {
    text-align: left;
    font-size: 2.75rem;
  }
  .engage-page > .hero > aside {
    max-width: 28rem;
  }
  .engage-page > .hero > article > p {
    font-size: 1.175rem;
    text-align: left;
  }
  .engage-page > .hero > article > .button-group {
    width: 20rem;
  }
  .engage-page h2 {
    font-size: 2rem;
    margin-top: 4rem;
  }
  .how-it-works {
    padding-bottom: 2rem;
  }
  .how-it-works > div {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
  }
  .how-it-works > div > article {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .how-it-works > div > article > aside {
    flex: 1;
    margin: auto;
    margin-bottom: 1rem;
  }
  .how-it-works > div > article > aside > img {
    object-fit: contain;
  }
  .how-it-works > div > article > p {
    text-align: center;
    font-size: 0.875rem;
  }
  .what-to-win > .prizes {
    flex-direction: row;
    gap: 2rem;
  }
  .what-to-win > .prizes > article {
    flex: 1;
    flex-direction: column;
  }
  .what-to-win > .prizes > article > footer > h3 {
    margin-top: 1.5rem;
  }
  .what-to-win > .prizes > article > footer > h5 {
    margin-bottom: 1rem;
  }
  .what-to-win > article:last-of-type {
    max-width: 48rem;
    margin: auto;
  }
  .what-to-win > article:last-of-type > h4 {
    text-align: center;
    font-size: 1.5rem;
  }
  .what-to-win > article:last-of-type > ul {
    text-align: center;
  }
  .how-possible {
    max-width: 60rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
  }
  .how-possible > div:first-of-type {
    flex-direction: row;
    margin-bottom: 2rem;
  }
  .how-possible > div {
    align-items: center;
    flex-direction: row;
    gap: 5rem;
  }
  .how-possible > div > article {
    flex: 1;
  }
  .how-possible > div > aside {
    flex: 1;
    max-width: 20rem;
  }
  .how-possible > div > article > h3 {
    font-size: 1.5rem;
  }
  .how-possible > div > article > p {
    font-size: 0.875rem;
  }
  .how-to-join {
    margin: auto;
    max-width: 60rem;
  }
  .how-to-join > div {
    flex-direction: row;
    gap: 5rem;
    align-items: center;
  }

  .how-to-join > div > aside {
    flex: 1;
  }
  .how-to-join > div > article {
    flex: 1;
  }
  .how-to-join > div > article > h3 {
    font-size: 1.5rem;
  }
  .how-to-join > div > article > h3 > span {
    font-size: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.175rem;
    margin-right: 1rem;
  }
  .how-to-join > div > article > p {
    font-size: 0.875rem;
    flex: 1;
  }
}
