.base-re-captcha-text {
  color: var(--primary-color);
  font-size: 0.75rem;
  text-align: center;
  /* padding: 1rem; */
  padding-top: 0;
}
.base-re-captcha-text a {
  color: var(--primary-color);
}
